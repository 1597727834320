import './App.scss';
import { Basemaps } from './components/basemaps/Basemaps';
import { DrawSelectedItem } from './components/map/draw-selected-item/DrawSelectedItem';
import { Map } from './components/map/Map'
import { Tile } from './components/map/tile-layer/Tile';
import { useAppSelector } from './components/state/hooks';
import { Toolbar } from './components/toolbar/Toolbar';

function App() {
  const centerX = 3911741.3595;
  const centerY = 4746759.8235;
  const zoom = 7;

  const basemaps = useAppSelector(state => state.basemaps.value);
  const searchListVisibilty = useAppSelector(state => state.searchResults.searchListVisibilty)
  const selectedListVisibilty = useAppSelector(state => state.searchResults.selectedListVisibilty)

  return (
    <div className='App'>
      <div className='toolbar'>
        <Toolbar />
      </div>
      <div className='map'>
        <Map centerX={centerX} centerY={centerY} zoom={zoom}>
          {basemaps.length > 0 && basemaps.map((basemap: any) => basemap.visible &&
            <Tile
              layername={basemap.layername}
              key={basemap.url} url={basemap.url}
              visible={basemap.visible}
            />
          )}
          {basemaps.length <= 0 &&
            <Tile
              layername='Google Street'
              key='https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png' url='https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              visible={true}
            />
          }
          <DrawSelectedItem />
        </Map>
      </div>
      <div className={searchListVisibilty || selectedListVisibilty ? 'basemaps-left' : 'basemaps'}>
        <Basemaps />
      </div>

    </div>
  );
}

export default App;

import { Button as PrimeButton } from 'primereact/button';
import './Button.scss';

interface IProps {
    label?: string,
    svg?: string,
    svgFocused?: string,
    onClick?: () => void,
    tooltip?: string
    moreStyle?: string,
    active?:boolean,
}
export const Button = (props: IProps) => {

    const handleClick = () => {
        if (typeof props.onClick === 'function') {
            props.onClick();
        }
    }

    return (
        <PrimeButton
            label={props.label}
            tooltip={props.tooltip}
            className={`${props.moreStyle} ${props.active ? 'button-active p-button-sm' : 'p-button-sm'} }`}
            // tooltipOptions={{ position: 'top', showDelay: 1000, hideDelay: 300 }}
            onClick={handleClick}
        >
            {props.svg && ! props.active && <img src={props.svg} />}
            {props.svg && props.active && <img src={props.svgFocused} />}
        </PrimeButton>
    )
};

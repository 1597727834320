import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    value: {
        url: '',
        icon: '',
        layername: '',
        visible: false
    },
}

export const basemapSlice = createSlice({
    name: 'basemap',
    initialState,
    reducers: {
        setBasemaps: (state: any, action) => {
            state.value = action.payload;
        },
        changeVisibility: (state: any, action) => {
            const { index, visibility } = action.payload as { index: number, visibility: boolean };

            state.value.map((basemap: any) => {
                basemap.visible = false
            })
            state.value[index].visible = visibility
        }
    },
})

export const { setBasemaps, changeVisibility } = basemapSlice.actions


export default basemapSlice.reducer
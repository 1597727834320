import './Search.scss';
import { InputText } from 'primereact/inputtext';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SeatchResultRestApi } from '../../utils/restapi/searchResult';
import { useAppSelector } from '../state/hooks';
import { ListBox } from 'primereact/listbox';
import { Button } from '../shared/button/Button';
import { selectedListVisibilty, setResults, searchListVisibilty, setResultTime, setResultScore, setHoveredResult, setSelectedResult, setClickedFeatureId } from '../state/features/searchResult';

export const Search = () => {
    const padding = {
        padding: '10px',
    }
    const padding2 = {
        padding: '7px 13px',
    }

    const alignment = {
        display: 'flex',
        alignItems: 'center',
        fontWeight: '500',
        color: '#495057 !important'
    }
    const line = {
        margin: '0px',
        height: '1px',
        backgroundColor: 'rgb(175 175 175 / 53%)',
        border: 'none'
    }

    const underline = {
        color: '#007BFF !important',
        textDecoration: 'underline',
    }

    const [selectedItem, setSelectedItem] = useState<any>('');
    const [detailsData, setDetailsData] = useState<any>(null);

    const searchListBoxVisibilty = useAppSelector(state => state.searchResults.searchListVisibilty);
    const selectedListBoxVisibilty = useAppSelector(state => state.searchResults.selectedListVisibilty);
    // const extentedResult = useAppSelector(state => state.searchResults.extentedValue);
    const clickedFeatureId = useAppSelector(state => state.searchResults.clickedFeatureId);
    const selectedCity = useAppSelector(state => state.dropdownResults.city);
    const selectedTown = useAppSelector(state => state.dropdownResults.town);
    const selectedNeighborhood = useAppSelector(state => state.dropdownResults.neighborhood);

    const type = useAppSelector(state => state.filters.type);
    const category = useAppSelector(state => state.filters.category);
    const subcategory = useAppSelector(state => state.filters.subcategory);

    const searchResults = useAppSelector(state => state.searchResults.value);
    const time = useAppSelector(state => state.searchResults.resultTime)
    const score = useAppSelector(state => state.searchResults.resultScore)

    const dispatch = useDispatch();
    const [searchResultsArray, setSearchResultsArray] = useState([])
    const [extentedFeatureNumber, setExtentedFeatureNumber] = useState(null)


    // useEffect(() => {
    //     const filteredArray: any = [];
    //     extentedResult.find((extentedRes: any) => {
    //         searchResults.find((res: any) => {
    //             if (res.id === extentedRes.id) {
    //                 filteredArray.push(res);
    //             }
    //         })
    //     })

    //     setSearchResultsArray(filteredArray);
    //     if (filteredArray.length > 0) {
    //         setExtentedFeatureNumber(filteredArray.length)
    //     }
    //     else {
    //         setExtentedFeatureNumber(null)

    //     }
    // }, [extentedResult])

    useEffect(() => {
        if (selectedCity || selectedTown || selectedNeighborhood || selectedItem || type.length > 0 || category.length > 0 || subcategory.length > 0) {
            getResults();
        }
        else {
            dispatch(setResults([]))
        }
    }, [selectedCity, selectedTown, selectedNeighborhood, selectedItem, type, category]);

    useEffect(() => {
        if (clickedFeatureId) {
            const clickedItem = searchResults.find((res: any) => res.id === clickedFeatureId)
            const item = {
                value: clickedItem
            }
            onSelect(item)
        }

    }, [clickedFeatureId])

    const getResults = () => {
        SeatchResultRestApi.getSearchResult(
            selectedItem,
            selectedCity.ad,
            selectedTown.ad,
            selectedNeighborhood.ad,
            type,
            category,
            subcategory
        ).then((res: any) => {
            const resultArray = res.data.hits.hits.map((value: any) => value._source)
            const resultTime = millisToSeconds(res.data.took);
            const resultScore = res.data.hits.total.value;
            dispatch(setResultTime(resultTime))
            dispatch(setResultScore(resultScore))
            resultArray.map((res: any) => {
                let geomString = res.geom;
                let mySubString = geomString.substring(
                    geomString.indexOf('(') + 1,
                    geomString.lastIndexOf(')')
                );
                const parsed = mySubString.split(' ')
                const geom = [+parsed[0], +parsed[1]]
                res.geom = geom;

            })
            dispatch(setResults(resultArray))
            setSearchResultsArray(resultArray)
        })
    }

    const onMouseHover = (itemId: any) => {
        const hoveredResult = searchResults.find((item: any) => item.id === itemId);
        dispatch(setHoveredResult({ result: hoveredResult, show: true }))
    }

    const onMouseLeave = (itemId: any) => {
        const hoveredResult = searchResults.find((item: any) => item.id === itemId);
        dispatch(setHoveredResult({ result: hoveredResult, show: false }))
    }

    const itemTemplate = (item: any) => {
        const itemType =  item.type === 'Okul' ? item.subcategory : (item.type).toLowerCase();
        const okulType = item.type === 'Okul' ? item.subcategory : null;
        let icon = okulType ? okulType : item.type;

        if (itemType === 'üniversite') {
            icon = itemType + '_' + (item.category)?.toLowerCase();
        }

        if (itemType === 'yurt') {
            icon = itemType + '_' + (item.category)?.toLowerCase();
        }

        return <div className='item-template' onMouseEnter={() => onMouseHover(item.id)} onMouseLeave={() => onMouseLeave(item.id)}>
            <div style={padding}>
                <span style={alignment}>
                    <div className='icon-container'>
                        <img className='categoty-icon' src={`icons/${icon}.svg`} alt='empty' />
                    </div>
                    <span style={{ color: '#2D788B', whiteSpace: 'initial' }}>{item.ad}</span>
                </span>
            </div>
            <hr style={line} />
            {item.telefon && <div style={padding2}>
                <span style={alignment}>
                    <div className='icon-container'>
                        <img src='icons/telephone.svg' alt='tel-icon' />
                    </div>
                    <a href={'tel:' + item.telefon} target="blank" style={{ textDecoration: 'none', color: '#495057', wordBreak: 'break-all' }}>
                        {item.telefon}
                    </a>
                </span>
            </div>}
            {item.web_adresi && <div style={padding2}>
                <span style={alignment}>
                    <div className='icon-container'>
                        <img src='icons/website.svg' alt='web-icon' />
                    </div>
                    <a href={item.web_adresi} target="blank" style={{
                        textDecoration: 'none',
                        color: '#007BFF',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}>
                        <span style={underline} onClick={(e) => handeChild(e)}>{item.web_adresi}
                        </span>
                    </a>
                </span>
            </div>}
        </div>
    }

    const selectedItemTemplate = (item: any) => {
        if (item.type === 'Okul') {
            return <div className='selected-item-template'>
                <div className='header'>
                    <i className='pi pi-angle-left' onClick={goBack} ></i>
                    <div><strong style={{ fontSize: '16px' }} >{item.ad}</strong></div>
                </div>
                <div className='tags'>
                    <span>
                        <a ><p>{item.okul_type}</p></a>
                    </span>
                </div>
                <br />
                <br />
                <div className='detail'>
                    {item.telefon && <div style={padding2}>
                        <span style={alignment}>
                            <div className='icon-container'>
                                <img src='icons/telephone.svg' alt='tel-icon' />
                            </div>
                            <a href={'tel:' + item.telefon} target="blank" style={{ textDecoration: 'none', color: '#495057', wordBreak: 'break-all' }}>
                                {item.telefon}
                            </a>
                        </span>
                    </div>}
                    {item.web_adresi && <div style={padding2}>
                        <span style={alignment}>
                            <div className='icon-container'>
                                <img src='icons/website.svg' alt='web-icon' style={{ fontSize: '14px' }} />
                            </div>
                            <a href={item.web_adresi} target="blank" style={{
                                textDecoration: 'none',
                                color: '#007BFF',
                                wordBreak: 'break-all'
                            }}>
                                <span style={underline} onClick={(e) => handeChild(e)}>{item.web_adresi}
                                </span>
                            </a>
                        </span>
                    </div>}
                    {item.adres && <div style={padding2}>
                        <span style={alignment}>
                            <div className='icon-container'>
                                <img src='icons/location.svg' alt='location-icon' />
                            </div>
                            <span style={{ whiteSpace: 'initial' }}>{item.adres}</span>
                        </span>
                    </div>}
                    {item.ogrenci_sayisi && <div style={padding2}>
                        <span style={alignment}>
                            <div className='icon-container'>
                                <img src='icons/student.svg' alt='student-icon' />
                            </div>
                            <span style={{ whiteSpace: 'initial' }}>{item.ogrenci_sayisi} Öğrenci Kapasitesi </span>
                        </span>
                    </div>}

                </div>
            </div>
        }
        if (item.type === 'Yurt') {
            return <div className='selected-item-template'>
                <div className='header'>
                    <i className='pi pi-angle-left' onClick={goBack} ></i>
                    <div><strong style={{ fontSize: '16px' }} >{item.ad}</strong></div>
                </div>
                <div className='tags'>
                    <span style={{ display: 'flex' }}>
                        <a><p>{item.yurt_gender}</p></a>
                        <a><p>{item.yurt_type}</p></a>
                    </span>
                </div>
                <br />
                <br />
                <div className='detail'>
                    {item.telefon && <div style={padding2}>
                        <span style={alignment}>
                            <div className='icon-container'>
                                <img src='icons/telephone.svg' alt='tel-icon' />
                            </div>
                            <a href={'tel:' + item.telefon} target="blank" style={{ textDecoration: 'none', color: '#495057', wordBreak: 'break-all' }}>
                                {item.telefon}
                            </a>                        </span>
                    </div>}
                    {item.web_adresi && <div style={padding2}>
                        <span style={alignment}>
                            <div className='icon-container'>
                                <img src='icons/website.svg' alt='web-icon' />
                            </div>
                            <a href={item.web_adresi} target="blank" style={{
                                textDecoration: 'none',
                                color: '#007BFF',
                                wordBreak: 'break-all'
                            }}>
                                <span style={underline} onClick={(e) => handeChild(e)}>{item.web_adresi}
                                </span>
                            </a>
                        </span>
                    </div>}
                    {item.adres && <div style={padding2}>
                        <span style={alignment}>
                            <div className='icon-container'>
                                <img src='icons/location.svg' alt='location-icon' />
                            </div>
                            <span style={{ whiteSpace: 'initial' }}>{item.adres}</span>
                        </span>
                    </div>}
                    {item.kapasite && <div style={padding2}>
                        <span style={alignment}>
                            <div className='icon-container'>
                                <img src='icons/size.svg' alt='size-icon' />
                            </div>
                            <span style={{ whiteSpace: 'initial' }}>{item.kapasite} Kapasiteli</span>
                        </span>
                    </div>}
                </div>
            </div>
        }
        if (item.type === 'Üniversite') {
            return <div className='selected-item-template'>
                <div className='header'>
                    <i className='pi pi-angle-left' onClick={goBack} ></i>
                    <div><strong style={{ fontSize: '16px' }} >{item.ad}</strong></div>
                </div>
                <div className='tags'>
                    <span style={{ display: 'flex' }}>
                        {item.uni_type && <a><p>{item.uni_type}</p></a>}</span>
                </div>
                <br />
                <br />
                <div className='detail'>
                    {item.telefon && <div style={padding2}>
                        <span style={alignment}>
                            <div className="icon-container">
                                <img src='icons/telephone.svg' alt='tel-icon' />
                            </div>
                            <a href={'tel:' + item.telefon} target="blank" style={{ textDecoration: 'none', color: '#495057', wordBreak: 'break-all' }}>
                                {item.telefon}
                            </a>
                        </span>
                    </div>}
                    {item.web_adresi && <div style={padding2}>
                        <span style={alignment}>
                            <div className="icon-container">
                                <img src='icons/website.svg' alt='web-icon' />
                            </div>
                            <a href={item.web_adresi} target="blank" style={{
                                textDecoration: 'none',
                                color: '#007BFF',
                                wordBreak: 'break-all'
                            }}>
                                <span style={underline} onClick={(e) => handeChild(e)}>{item.web_adresi}
                                </span>
                            </a>
                        </span>
                    </div>}
                    {item.adres && <div style={padding2}>
                        <span style={alignment}>
                            <div className="icon-container">
                                <img src='icons/location.svg' alt='location-icon' />
                            </div>
                            <span style={{ whiteSpace: 'initial' }}>{item.adres}</span>
                        </span>
                    </div>}
                    {item.eposta && <div style={padding2}>
                        <span style={alignment}>
                            <div className="icon-container">
                                <img src='icons/mail.svg' alt='mail-icon' />
                            </div>
                            <span style={{ whiteSpace: 'initial' }}>{item.eposta}</span>
                        </span>
                    </div>}
                </div>
                <div className='buttons'>
                    <div className='route'>
                        <Button svg='icons/how-to-go.svg' label='Rota' />
                    </div>
                    <div className='share'>
                        <Button svg='icons/share.svg' />
                    </div>
                </div>
            </div>
        }

    }

    const onSelect = (e: any) => {
        setDetailsData([e.value])
        dispatch(setSelectedResult(e.value));
        dispatch(selectedListVisibilty(true))
        dispatch(searchListVisibilty(false))
    }
    const goBack = () => {
        dispatch(searchListVisibilty(true))
        dispatch(setSelectedResult({}));
        setDetailsData(null)
        dispatch(setClickedFeatureId(null));
        dispatch(selectedListVisibilty(false))
        dispatch(setResults(searchResultsArray))
    }
    const onClose = () => {
        dispatch(searchListVisibilty(false))
        dispatch(selectedListVisibilty(false))
        setSelectedItem('');
        setDetailsData(null)
        dispatch(setResultScore(0))
        dispatch(setResultTime(0))
        dispatch(setResults([]));
    }

    const onInputChange = (e: any) => {
        setSelectedItem(e.target.value);
        setDetailsData(null)
        if (e.target.value) {
            dispatch(searchListVisibilty(true))
        }
        else {
            onClose()
        }
        if(selectedListBoxVisibilty){
            dispatch(selectedListVisibilty(false))
            setDetailsData(null)
        }
    }

    const handeChild = (e: any) => {
        e.stopPropagation();
    }

    const millisToSeconds = (ms: number) => {
        var seconds: any = ((ms % 60000) / 1000);
        return (
            (seconds < 10 ? "" : "") + seconds
        );
    }

    return <>
        <span>
            <InputText value={selectedItem} onChange={(e) => onInputChange(e)} placeholder='Hızlı ara' />
            {selectedItem && <i className='pi pi-times close-button' onClick={onClose} ></i>}
        </span>
        {time !== 0 && <div className='result-area'>
            <div className='result-info'>
                <img src="icons/info.svg" alt="info" style={{ paddingRight: '5px' }} />
                <div className='text'>{extentedFeatureNumber ? extentedFeatureNumber : score} sonuç {time} saniyede listelendi</div>
            </div>
        </div>}
        { time !== 0 && searchListBoxVisibilty && <ListBox options={searchResultsArray.length > 1 ? searchResultsArray : searchResults} itemTemplate={itemTemplate} optionLabel='name' onChange={(e) => onSelect(e)} />}
        {selectedListBoxVisibilty && searchResults.length > 0 && <ListBox options={detailsData} itemTemplate={selectedItemTemplate} />}
    </>;
}; 

import './Toolbar.scss';
import { Button } from "../shared/button/Button";
import { Search } from "../search/Search";
import { DropdownGroup } from "../dropdown-group/DropdownGroup";
import { useState } from "react";
import { ActiveButtonState } from '../shared/button-state/ButtonState';
import { FilterData } from '../filter-data/FilterData';
import { Distance } from '../distance/Distance';
import { Route } from '../route/Route';

export const Toolbar = () => {
    const [activeButtonType, setActiveButtonType] = useState<string>(ActiveButtonState.DEFAULT);

    const toggleDistance = () => {
        if (activeButtonType === ActiveButtonState.DISTANCE) {
            setActiveButtonType(ActiveButtonState.DEFAULT)
            return;
        }
        setActiveButtonType(ActiveButtonState.DISTANCE)
    }

    const toggleRoute = () => {
        if (activeButtonType === ActiveButtonState.ROUTE) {
            setActiveButtonType(ActiveButtonState.DEFAULT)
            return;
        }
        setActiveButtonType(ActiveButtonState.ROUTE)
    }

    const toggleFilters = () => {
        if (activeButtonType === ActiveButtonState.FILTERS) {
            setActiveButtonType(ActiveButtonState.DEFAULT)
            return;
        }
        setActiveButtonType(ActiveButtonState.FILTERS)
    }

    return <div className="Toolbar">
        <div className='left-content'>
            <Search />
            <DropdownGroup />
        </div>
        <div className='right-content'>
            <Button svg='icons/distance.svg' onClick={toggleDistance} svgFocused='focused-icons/distance-focused.svg' active={activeButtonType === ActiveButtonState.DISTANCE} />
            {activeButtonType === ActiveButtonState.DISTANCE && <Distance />}
            <Button svg='icons/how-to-go.svg' onClick={toggleRoute} svgFocused='focused-icons/route-focused.svg' active={activeButtonType === ActiveButtonState.ROUTE} />
            {activeButtonType === ActiveButtonState.ROUTE && <Route />}
            <Button svg='icons/filters.svg' onClick={toggleFilters} svgFocused='focused-icons/filters-focused.svg' active={activeButtonType === ActiveButtonState.FILTERS} />
            {activeButtonType === ActiveButtonState.FILTERS && <FilterData />}
        </div>
    </div>;
};
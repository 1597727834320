import axios from 'axios';

const url = 'https://egitim.ankageo.com/api/';


async function getCities() {
    return axios.get(url + 'il');
}

async function getTowns(selectedCity: string) {
    return axios.get(url + 'ilce?il=' + selectedCity)
}

async function getNeighborhood(selectedCity: string, selectedTown:string) {
    return axios.get(url + 'mahalle?il=' + selectedCity + '&ilce=' + selectedTown) 
}

export const CitiesRestApi = {
    getCities, getTowns, getNeighborhood
}
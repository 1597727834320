import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { changeVisibility, setBasemaps } from '../state/features/basemapSlice';
import { useAppSelector } from '../state/hooks';
import './Basemap.scss';

export const Basemaps = () => {
    const dispatch = useDispatch();
    const [selectedBasemap, setSelectedBasemap] = useState<any>(null);
    const [showBasemaps, setShowBasemaps] = useState<Boolean>(false);
    const [results, setResults] = useState<any>([]);
    const basemaps = useAppSelector(state => state.basemaps.value)
    const searchListVisibilty = useAppSelector(state => state.searchResults.searchListVisibilty)
    const selectedListVisibilty = useAppSelector(state => state.searchResults.selectedListVisibilty)
    const searchResults = useAppSelector(state => state.searchResults.value);

    useEffect(() => {
        const basemapArray = [
            {
                url: 'https://{a-c}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                icon: 'basemap-icons/open-street.png',
                layername: 'OSM',
                visible: true,
            },
            {
                url: 'http://mt0.google.com/vt/lyrs=r&hl=en&x={x}&y={y}&z={z}',
                icon: 'basemap-icons/google-street.png',
                layername: 'Google Sokak',
                visible: false,
            },
            {
                url: 'https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
                icon: 'basemap-icons/google-uydu.png',
                layername: 'Google Uydu',
                visible: false,
            },
            {
                url: 'http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
                icon: 'basemap-icons/google-hibrit.png',
                layername: 'Google Hibrit',
                visible: false,
            },
            {
                url: 'https://mt2.google.com/vt/lyrs=p@113&hl=tr&&x={x}&y={y}&z={z}',
                icon: 'basemap-icons/google-arazi.jpeg',
                layername: 'Google Arazi',
                visible: false,
            },
            {
                url: 'http://basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png',
                icon: 'basemap-icons/koyu-altlik.png',
                layername: 'Carto Dark',
                visible: false,
            },
        ]

        dispatch(setBasemaps(basemapArray));
    }, [])

    useEffect(() => {
        if (basemaps.length > 0) {
            const selectedBasemap = basemaps.find((basemap: any) => {
                return basemap.visible === true
            })
            setSelectedBasemap(selectedBasemap)
        }
    }, [basemaps]);

    useEffect(() => {
        setResults(searchResults)
    }, [searchResults]);


    const changeBasemapVisibility = (index: number, visibility: boolean) => {
        dispatch(changeVisibility({ index, visibility }))
    }

    return (
        <div className={searchListVisibilty || selectedListVisibilty || results.length > 0 ? 'Basemap-left' : 'Basemap'}>
            <div className='basemap-button' onClick={() => setShowBasemaps(!showBasemaps)}>
                {selectedBasemap && <img className='image-box' src={selectedBasemap.icon} alt='googleStreet' />
                }
            </div>
            {showBasemaps && <div className='basemap-group'>
                {basemaps.length > 0 && basemaps.map((basemap: any, index: number) =>
                    <div className='basemap' key={index} onClick={() => changeBasemapVisibility(index, true)}>
                        <img className='image-box' src={basemap.icon} alt='googleStreet' />
                        {/* <p className='title'>{basemap.layername}</p> */}
                    </div>
                )}
            </div>}
        </div>
    )
};

import { Feature } from "ol";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { useContext, useEffect, useState } from "react";
import { useAppSelector } from "../../state/hooks";
import { MapContext } from "../MapContext";
import Point from "ol/geom/Point";
import { Stroke, Style, Icon, Fill } from 'ol/style';
import Text from 'ol/style/Text';
import { useDispatch } from "react-redux";
import { setClickedFeatureId, setExtentedResults } from "../../state/features/searchResult";
import { Cluster } from "ol/source";

export const DrawSelectedItem = () => {
    const searchResults = useAppSelector(state => state.searchResults.value);
    const selectedResult = useAppSelector(state => state.searchResults.selectedResult);
    const hoveredResult = useAppSelector(state => state.searchResults.hoveredResult)
    const searchListVisibilty = useAppSelector(state => state.searchResults.searchListVisibilty)
    const selectedListVisibilty = useAppSelector(state => state.searchResults.selectedListVisibilty);
    const selectedNeighborhood = useAppSelector(state => state.dropdownResults.neighborhood)

    const [defaultExtent, setDefaultExtent] = useState<any>([3048920.18421693, 4159111.9500435647, 4774562.534783069, 5334407.696956435])
    const [vector, setVector] = useState<any>(new VectorLayer())
    const [vectorSource, setSource] = useState<any>(new VectorSource())
    const [extent, setExtent] = useState<any>([])

    const map = useContext(MapContext);

    let featuresArray: any = [];

    const dispatch = useDispatch();

    useEffect(() => {
        const vectorSource = new VectorSource();

        // const clusterSource = new Cluster({
        //     // distance: parseInt('20', 10),
        //     // minDistance: parseInt('2', 10),
        //     source: vectorSource,
        // });

        const vector = new VectorLayer({
            source: vectorSource,
            zIndex: 100,
            // source: clusterSource,
        });

        setVector(vector)
        setSource(vectorSource)
        map.addLayer(vector);
        mapEvents();

    }, [map])

    useEffect(() => {
        if (selectedResult.ad) {
            vectorSource.clear();

            drawAllPoints({
                type: (selectedResult.type).toLowerCase(),
                category: (selectedResult.category).toLowerCase(),
                coordinates: selectedResult.geom,
                okul_type: selectedResult.type === 'Okul' ? selectedResult.subcategory : null,
                id: selectedResult.id,
                ad: selectedResult.ad
            })
            var extent = vector.getSource().getExtent();
            map.getView().fit(extent, { padding: [150, 150, 130, 150], maxZoom: 14 });
        }

    }, [selectedResult]);

    useEffect(() => {
        let allFeatures = vectorSource.getFeatures();
        const hovered = hoveredResult.result
        let hoveredFeature = allFeatures.find((feature: any) => feature.getId() === hovered.id)
        if (hoveredResult.show) {
            const okulType = (hovered.subcategory)?.toLowerCase()
            const type = (hovered.type).toLowerCase()
            let icon = type === 'okul' ? okulType : type;
            const zIndex = type === 'üniversite' ? 12 : 11;

            if (type === 'üniversite') {
                icon = type + '_' + (hovered.category)?.toLowerCase();
            }

            if (type === 'yurt') {
                icon = type + '_' + (hovered.category)?.toLowerCase();
            }

            hoveredFeature.setStyle(new Style({
                image: new Icon(({
                    anchor: [0.5, 30],
                    anchorXUnits: 'fraction',
                    anchorYUnits: 'pixels',
                    src: `icons/${icon}_h.svg`,
                })),
                text: new Text({
                    font: 'bold 16px Calibri,sans-serif',
                    fill: new Fill({ color: '#2d788b' }),
                    stroke: new Stroke({
                        color: '#fff', width: 2
                    }),
                    text: `${hovered.ad}`,
                    offsetY: -53,
                    textBaseline: 'top',
                    backgroundFill: new Fill({ color: '#ddecf0' }),
                    padding: [5, 5, 5, 5]
                }),
                zIndex: zIndex + 5
            }))

        }
        if (!hoveredResult.show) {
            vectorSource.getFeatures().find((feature: any) => {
                const properties = feature.getProperties();
                const type = (properties.type).toLowerCase();
                const okulType = (properties.okul_type)?.toLowerCase();

                let icon = okulType ? okulType : properties.type;
                // console.log(properties);
                
                const zIndex = properties.type === 'üniversite' ? 12 : 11;

                if (type === 'üniversite') {
                    icon = type + '_' + (properties.category)?.toLowerCase();
                }

                if (type === 'yurt') {
                    icon = type + '_' + (properties.category)?.toLowerCase();
                }


                feature.setStyle(new Style({
                    image: new Icon(({
                        anchor: [0.5, 10],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'pixels',
                        src: `icons/${icon}.svg`,
                    })),
                    zIndex: zIndex
                }))

            })
        }
    }, [hoveredResult]);

    useEffect(() => {
        if (selectedListVisibilty) {
            return;
        }
        vectorSource.clear();
        if (searchResults.length > 0) {
            searchResults.find((result: any) => {
                if (result) {
                    const resultType = result.type;
                    const resultCategory = result.category
                    drawAllPoints({
                        type: resultType.toLowerCase(),
                        coordinates: result.geom,
                        category: resultCategory?.toLowerCase(),
                        okul_type: result.type === 'Okul' ? result.subcategory : null,
                        id: result.id,
                        ad: result.ad
                    })
                    if (!vector.getSource()) {
                        return
                    }

                    var extent = vector.getSource().getExtent();
                    if (selectedListVisibilty || searchListVisibilty) {
                        map.getView().fit(extent, { padding: [150, 150, 130, 550], maxZoom: 14 });
                    }
                    else {
                        map.getView().fit(extent, { padding: [150, 150, 130, 150], maxZoom: 14 });
                    }
                }
                else {
                    vectorSource.clear()
                }
            })
        }
        if (searchResults.length === 0 && !selectedNeighborhood) {
            map.getView().fit(defaultExtent, { padding: [50, 50, 80, 50], maxZoom: 14 });
        }
    }, [searchResults, selectedListVisibilty]);

    // useEffect(() => {
    //     if (selectedListVisibilty) {
    //         var extent = vector.getSource().getExtent();
    //         map.getView().fit(extent, { padding: [150, 150, 130, 550], maxZoom: 14 });
    //     }
    //     if (!selectedListVisibilty && !selectedNeighborhood) {
    //         map.getView().fit(defaultExtent, { padding: [50, 50, 80, 50], maxZoom: 14 });
    //     }
    // }, [selectedListVisibilty])


    const mapEvents = () => {
        let selected: any = null;

        map.on('pointermove', (e) => {
            var pixel = map.getEventPixel(e.originalEvent);
            var hit = map.hasFeatureAtPixel(pixel);
            map.getViewport().style.cursor = hit ? 'pointer' : '';

            if (selected !== null) {
                const properties = selected.getProperties()
                const type = (properties.type)?.toLowerCase();

                const okulType = (properties.okul_type)?.toLowerCase();
                let icon = okulType ? okulType : properties.type;
                const zIndex = properties.type === 'üniversite' ? 12 : 11;


                if (type === 'üniversite') {
                    icon = type + '_' + (properties.category)?.toLowerCase();
                }

                if (type === 'yurt') {
                    icon = type + '_' + (properties.category)?.toLowerCase();
                }
                const okulStyle = new Style({
                    image: new Icon(({
                        anchor: [0.5, 10],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'pixels',
                        src: `icons/${icon}.svg`,
                    })),
                    zIndex: zIndex
                })

                selected.setStyle(okulStyle);
                selected = null;
            }

            map.forEachFeatureAtPixel(e.pixel, function (feature: any) {
                selected = feature;
                if (selected) {
                    const properties = selected.getProperties()
                    const okulType = (properties.okul_type)?.toLowerCase();
                    const type = (properties.type)?.toLowerCase();
                    let icon = okulType ? okulType : properties.type;
                    const zIndex = properties.type === 'üniversite' ? 12 : 11;


                    if (type === 'üniversite') {
                        icon = type + '_' + (properties.category)?.toLowerCase();
                    }

                    if (type === 'yurt') {
                        icon = type + '_' + (properties.category)?.toLowerCase();
                    }

                    selected.setStyle(new Style({
                        image: new Icon(({
                            anchor: [0.5, 30],
                            anchorXUnits: 'fraction',
                            anchorYUnits: 'pixels',
                            src: `icons/${icon}_h.svg`,
                        })),
                        text: new Text({
                            font: 'bold 16px Calibri,sans-serif',
                            fill: new Fill({ color: '#2d788b' }),
                            stroke: new Stroke({
                                color: '#fff', width: 2
                            }),
                            text: `${properties.ad}`,
                            offsetY: -53,
                            textBaseline: 'top',
                            backgroundFill: new Fill({ color: '#ddecf0' }),
                            padding: [5, 5, 5, 5]
                        }),
                        zIndex: zIndex + 5
                    }))
                }
                return feature;
            });

        });

        map.on('click', (e) => {
            map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
                const clickedFeatureId = feature.getId();
                const featuresArray = [];
                dispatch(setClickedFeatureId(clickedFeatureId))
            })
        })

        // map.on('movestart', (e) => {
        //     var extent = map.getView().calculateExtent(map.getSize());
        //     setExtent(extent)
        // })

    }

    useEffect(() => {
        if (extent[0] < 0) {
            return;
        }
        const featureArray: any = []
        vectorSource.forEachFeatureInExtent(extent, (feature: any) => {
            featureArray.push(feature)
        });

        let resultsInExtent: any = []
        featureArray.find((feature: any) => {
            searchResults.find((result: any) => {
                if (result.id === feature.getId()) {
                    resultsInExtent.push(result)
                }
            })
        })
        dispatch(setExtentedResults(resultsInExtent))
    }, [extent])


    const drawAllPoints = (properties: any) => {
        const line = new Point(properties.coordinates);
        line.transform('EPSG:4326', 'EPSG:3857');

        const feature = new Feature({
            geom: line,

        });

        featuresArray.push(feature);
        feature.setGeometryName('geom');
        feature.setId(properties.id);
        feature.setProperties({ ad: properties.ad, type: properties.type, category: properties.category, okul_type: properties.okul_type })

        const okulType = (properties.okul_type)?.toLowerCase();
        let icon = okulType ? okulType : properties.type;
        const zIndex = properties.type === 'üniversite' ? 12 : 11;

        if (properties.type === 'üniversite') {
            icon = properties.type + '_' + properties.category;
        }

        if (properties.type === 'yurt') {
            icon = properties.type + '_' + properties.category;
        }

        feature.setStyle(new Style({
            image: new Icon(({
                anchor: [0.5, 10],
                anchorXUnits: 'fraction',
                anchorYUnits: 'pixels',
                src: `icons/${icon}.svg`,
            })),
            zIndex: zIndex
        }))
        vectorSource.addFeature(feature);
    }
    return <></>;
};

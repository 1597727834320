import { PropsWithChildren, useContext, useEffect, useRef, useState } from 'react'
import { MapContext } from '../MapContext'
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import { TileLayerContext } from './TileLayerContext';
import { TileWMS } from 'ol/source';
import { useAppSelector } from '../../state/hooks';
import axios from 'axios';
import { GeoJSON } from "ol/format";
import { transform, transformExtent } from 'ol/proj';
import { get } from 'ol/proj';
import { is } from 'immer/dist/internal';

interface Props {
    url: string;
    visible: boolean;
    layername?: string;
    opacity?: number;
}
export const Tile = (props: PropsWithChildren<Props>) => {
    const map = useContext(MapContext)
    const t = useRef<TileLayer<any>>(new TileLayer());
    const t2 = useRef<TileLayer<any>>(new TileLayer());
    const tileLayer2: any = t2.current!;

    const selectedCity = useAppSelector(state => state.dropdownResults.city)
    const selectedTown = useAppSelector(state => state.dropdownResults.town)
    const selectedNeighborhood = useAppSelector(state => state.dropdownResults.neighborhood)
    const [defaultExtent, setDefaultExtent] = useState<any>([3048920.18421693, 4159111.9500435647, 4774562.534783069, 5334407.696956435])


    useEffect(() => {
        const tileLayer = t.current!;
        tileLayer.setSource(new XYZ({
            url: props.url
        }));

        tileLayer.setVisible(props.visible);
        tileLayer.setZIndex(-1)

        map.addLayer(tileLayer);




        return () => {
            map.removeLayer(tileLayer);
        }
    }, []);

    useEffect(() => {
        let selected = { filterName: '', value: '', layername: '' }

        if (selectedCity) {
            selected.value = selectedCity.uavtil;
            selected.filterName = 'uavtil';
            selected.layername = 'education:il';
        }
        else {
            return;
        }
        if (selectedTown) {
            selected.value = selectedTown.uavtilce;
            selected.filterName = 'uavtilce';
            selected.layername = 'education:ilce';
        }
        if (selectedNeighborhood) {
            selected.value = selectedNeighborhood.uavtmah;
            selected.filterName = 'uavtmah';
            selected.layername = 'education:mahalle';
        }

        tileLayer2.setSource(new TileWMS({
            params: { LAYERS: `${selected.layername}`, CQL_FILTER: `${selected.filterName}=${selected.value}` },
            url: 'https://egitim.ankageo.com/geoserver/education/wms',
            serverType: 'geoserver',
            crossOrigin: 'Anonymous',
        }))

        map.addLayer(tileLayer2);

        //extent için il ilçe ve mahalle wfs isteği

        axios.get(`https://egitim.ankageo.com/geoserver/education/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${selected.layername}&maxFeatures=50&outputFormat=application%2Fjson&cql_filter=${selected.filterName}=${selected.value}`).then((res) => {
            if (res) {
                const geojsonInstance = new GeoJSON();
                const feature = geojsonInstance.readFeature(res.data.features[0]);
                if (feature.getGeometry()) {
                    let extent = feature.getGeometry().getExtent();

                    extent = transformExtent(extent, get('EPSG:4326'), get('EPSG:3857'))
                    map.getView().fit(extent, { padding: [20, 20, 20, 20], maxZoom: 14 });
                }

            }

        })

        return () => {
            map.removeLayer(tileLayer2);
        }
    }, [selectedCity, selectedTown, selectedNeighborhood]);

    useEffect(() => {
        const tileLayer = t.current!;
        if (props.opacity) {
            tileLayer.setVisible(true)
            tileLayer.setOpacity(props.opacity / 100)
        }

    }, [props.opacity])

    return <TileLayerContext.Provider value={t.current}>{props.children}</TileLayerContext.Provider>;
}

function dispatch(arg0: { payload: any; type: string; }) {
    throw new Error('Function not implemented.');
}

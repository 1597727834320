import axios from 'axios';

const url = 'https://egitim.ankageo.com/api/';

async function getSearchResult(
    input?: string,
    selectedCity?: string,
    selectedTown?: string,
    selectedNeighborhood?: string,
    type?: any,
    category?: any,
    subcategory?: any
) {
    let typeResult = ''
    let categoryResult = ''
    let subcategoryResult = ''
    if (!input) { input = ' ' }
    if (!selectedCity) { selectedCity = '' }
    if (!selectedTown) { selectedTown = '' }
    if (!selectedNeighborhood) { selectedNeighborhood = '' }
    if (!type) {
        type = ''
    }
    else {
        for (let i = 0; i < type.length; i++) {
            typeResult = typeResult + ',' + type[i]
        }
        typeResult = typeResult.substring(1, typeResult.length)
    }
    if (!category) {
        category = ''
    }
    else {
        for (let i = 0; i < category.length; i++) {
            categoryResult = categoryResult + ',' + category[i]
        }
        categoryResult = categoryResult.substring(1, categoryResult.length)

    }
    if (!subcategory) {
        subcategory = ''
    }
    else {
        const repeated = subcategory.filter((item: any, index: any) => subcategory.indexOf(item) !== index)
        if (repeated.length > 0) {
            subcategory = repeated
            for (let i = 0; i < subcategory.length; i++) {
                subcategoryResult = subcategoryResult + ',' + subcategory[i]
            }
            subcategoryResult = subcategoryResult.substring(1, subcategoryResult.length)
            subcategoryResult = subcategoryResult + ',none'
        }
        else {
            for (let i = 0; i < subcategory.length; i++) {
                subcategoryResult = subcategoryResult + ',' + subcategory[i]
            }
            subcategoryResult = subcategoryResult.substring(1, subcategoryResult.length)
        }
    }

    return axios.get(url +
        'records?q=' + input +
        '&il=' + selectedCity +
        '&ilce=' + selectedTown +
        '&mahalle=' + selectedNeighborhood +
        '&type=' + typeResult +
        '&category=' + categoryResult +
        '&subcategory=' +  subcategoryResult 
    );

}

export const SeatchResultRestApi = {
    getSearchResult
}
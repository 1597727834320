import './Map.scss'
import 'ol/ol.css';
import View from 'ol/View';
import { PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Map as OlMap } from 'ol';
import { MapContext } from './MapContext';

interface IProps {
    centerX: number;
    centerY: number;
    zoom: number;
}
export const Map = (props: PropsWithChildren<IProps>) => {
    const mapRef = useRef(document.createElement('div'));
    const [olMap, setOlMap] = useState<OlMap | null>(null);
    const t = useRef<OlMap | null>(null);

    useEffect(() => {
        setTimeout(() => {
            const v = new View({
                center: [props.centerX, props.centerY],
                zoom: props.zoom,
            });
            const map = new OlMap({
                layers: [],
                view: v,
                controls: []
            });

            map.setTarget(mapRef.current)
            t.current = map;
            setOlMap(map);
            (window as any).map = map

            return () => map.dispose();
        }, 200)
    }, [mapRef]);

    return (
        <>
            <div className='Map' ref={mapRef}></div>
            {
                olMap &&
                <MapContext.Provider value={olMap}>
                    {props.children}
                </MapContext.Provider>
            }
        </>
    )
};

import { Dropdown } from 'primereact/dropdown';
import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { CitiesRestApi } from "../../utils/restapi/getCities";
import { dropdownState } from '../state/features/dropdownButtonState';
import { cityResult, neighborhoodResult, townResult } from '../state/features/dropdownResults';
import { selectedListVisibilty, setClickedFeatureId, setResults, setResultScore, setResultTime, setSelectedResult } from '../state/features/searchResult';
import { useAppSelector } from '../state/hooks';
import './DropdownGroup.scss';

export enum DropDownSelectState {
    CITY = 'CITY',
    TOWN = 'TOWN',
    NEIGBORHOOD = 'NEIGBORHOOD',
    DEFAULT = 'DEFAULT'
}

export const DropdownGroup = () => {
    const [cities, setCities] = useState<any>(null);
    const [towns, setTowns] = useState<any>(null);
    const [neighborhood, setNeighborhood] = useState<any>(null);

    const selectedCity = useAppSelector(state => state.dropdownResults.city)
    const selectedTown = useAppSelector(state => state.dropdownResults.town)
    const selectedNeighborhood = useAppSelector(state => state.dropdownResults.neighborhood)

    const dispatch = useDispatch();

    useEffect(() => {
        CitiesRestApi.getCities().then((res) => {
            const citiesArray = res.data
            setCities(citiesArray)
        })
    }, []);

    useEffect(() => {
        setTowns(undefined)
        setNeighborhood(undefined)
        dispatch(townResult(''))
        dispatch(neighborhoodResult(''))
        if (selectedCity) {
            CitiesRestApi.getTowns(selectedCity.ad).then((res) => {
                const townsArray = res.data;
                sortArrayAlphabetically(townsArray)
                setTowns(townsArray)
            })
        }
        else {

        }
    }, [selectedCity]);

    useEffect(() => {
        setNeighborhood(undefined)
        dispatch(neighborhoodResult(''))
        if (!selectedTown) {
            dispatch(dropdownState(DropDownSelectState.CITY))
        }

        if (selectedTown) {
            CitiesRestApi.getNeighborhood(selectedCity.ad, selectedTown.ad).then((res) => {
                const neighborhoodArray = res.data
                sortArrayAlphabetically(neighborhoodArray)
                setNeighborhood(neighborhoodArray)
            })
        }
    }, [selectedTown]);

    useEffect(() => {
        if (!selectedNeighborhood) {
            dispatch(dropdownState(DropDownSelectState.TOWN))
        }
    }, [selectedNeighborhood])

    const onCityChange = (e: any) => {
        // dispatch(searchListVisibilty(true))
        dispatch(setSelectedResult({}));
        // setDetailsData(null)
        dispatch(setClickedFeatureId(null));
        dispatch(selectedListVisibilty(false))
        // dispatch(setResults(searchResultsArray))

        if (e.value) {
            dispatch(cityResult(e.value))
            dispatch(dropdownState(DropDownSelectState.CITY))
        }
        if (!e.value) {
            dispatch(cityResult(''))
            dispatch(townResult(''))
            dispatch(neighborhoodResult(''))
            dispatch(setResults([]));
            dispatch(setResultScore(0))
            dispatch(setResultTime(0))
            dispatch(setSelectedResult({}));
        }
    }

    const onTownsChange = (e: any) => {
        if (e.value) {
            dispatch(townResult(e.value))
            dispatch(dropdownState(DropDownSelectState.TOWN))
        }
        if (!e.value) {
            dispatch(townResult(''))
            dispatch(neighborhoodResult(''))
            dispatch(dropdownState(DropDownSelectState.CITY))
        }
    }

    const onNeighborhoodChange = (e: any) => {
        if (e.value) {
            dispatch(neighborhoodResult(e.value))
            dispatch(dropdownState(DropDownSelectState.NEIGBORHOOD))
        }
        else {
            dispatch(neighborhoodResult(''))
        }
    }

    const sortArrayAlphabetically = (array: any[]) => {
        array.sort(function (a, b) {
            if (a.ad < b.ad) { return -1; }
            if (a.ad > b.ad) { return 1; }
            return 0;
        })
    }

    return (
        <>
            <Dropdown showClear={selectedCity} value={selectedCity} options={cities} onChange={onCityChange} optionLabel="ad" placeholder="İl" ></Dropdown>
            {selectedCity && towns && <Dropdown showClear={selectedTown} value={selectedTown} options={towns} onChange={onTownsChange} optionLabel="ad" placeholder="İlçe" />}
            {selectedTown && neighborhood && <Dropdown showClear={selectedNeighborhood} value={selectedNeighborhood} options={neighborhood} onChange={onNeighborhoodChange} optionLabel="ad" placeholder="Mahalle" />}
        </>
    )
};

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../shared/button/Button';
import { setAllFilters, setCategory, setFilterDisabledStatus, setFilterSelectedStatus, setSubcategory, setType } from '../state/features/filterSlice';
import { setResultScore, setResultTime } from '../state/features/searchResult';
import { useAppSelector } from '../state/hooks';
import './FilterData.scss';

export const FilterData = () => {

    const dispatch = useDispatch();
    const allFilters = useAppSelector(state => state.filters.allFilters)
    const resultTime = useAppSelector(state => state.searchResults.resultTime)
    const resultScore = useAppSelector(state => state.searchResults.resultScore)

    const [time, setTime] = useState(0)
    const [score, setScore] = useState(0)
    const [tagname, setTagname] = useState('')

    const [action, setAction] = useState(false)
    let selectedArray: any = []
    let typeArray: any = [];
    let categoryArray: any = [];
    let subcategoryArray: any = [];

    let filters = [
        {
            tagName: 'Üniversite',
            selected: false,
            icon: 'icons/üniversite.svg',
            type: 'group1',
        },
        {
            tagName: 'Yurt',
            selected: false,
            icon: 'icons/yurt.svg',
            type: 'group1',
        },
        {
            tagName: 'Okul',
            selected: false,
            icon: 'icons/okul.svg',
            type: 'group1',
        },
        {
            tagName: 'Devlet',
            selected: false,
            icon: '',
            type: 'group3',
            disabled: true
        },
        {
            tagName: 'Özel',
            selected: false,
            icon: '',
            type: 'group3',
            disabled: true
        },
        {
            tagName: 'Anaokulu',
            selected: false,
            icon: 'icons/anaokulu.svg',
            type: 'group2',
            disabled: true
        },
        {
            tagName: 'İlkokul',
            selected: false,
            icon: 'icons/ilkokul.svg',
            type: 'group2',
            disabled: true
        },
        {
            tagName: 'Ortaokul',
            selected: false,
            icon: 'icons/ortaokul.svg',
            type: 'group2',
            disabled: true
        },
        {
            tagName: 'Lise',
            selected: false,
            icon: 'icons/lise.svg',
            type: 'group2',
            disabled: true
        },
        {
            tagName: 'Kolej',
            selected: false,
            icon: 'icons/kolej.svg',
            type: 'group2',
            disabled: true
        },
        {
            tagName: 'Kız',
            selected: false,
            icon: '',
            type: 'group4',
            disabled: true
        },
        {
            tagName: 'Erkek',
            selected: false,
            icon: '',
            type: 'group4',
            disabled: true
        },
        {
            tagName: 'Karma',
            selected: false,
            icon: '',
            type: 'group4',
            disabled: true
        },

    ]

    useEffect(() => {
        if (!allFilters[0].tagName) {
            dispatch(setAllFilters(filters))
        }
    }, [])

    useEffect(() => {
        dispatch(setType([]));
        dispatch(setCategory([]));
        dispatch(setSubcategory([]));

        selectedArray = allFilters.filter((filter: any) => { return filter.selected })
        typeArray = []
        categoryArray = []
        subcategoryArray = []
        if (selectedArray.length > 0) {
            selectedArray.find((filter: any) => {
                if (filter.type === 'group2') {
                    subcategoryArray.push(filter.tagName);
                }
                if (filter.type === 'group4') {
                    subcategoryArray.push(filter.tagName);
                }
                if (filter.type === 'group3') {
                    categoryArray.push(filter.tagName)
                }
                if (filter.type === 'group1') {
                    typeArray.push(filter.tagName);
                }

            })
            //iki tane seciliysa sublari gonder
            if (typeArray.length > 1) {
                for (let i = 0; i < typeArray.length; i++) {
                    if (typeArray[i] === 'Üniversite' && !(subcategoryArray.includes('none'))) {
                        subcategoryArray = [
                            ...subcategoryArray,
                            'none'
                        ]
                    }
                    if (typeArray[i] === 'Yurt' && !(subcategoryArray.includes('Kız') && subcategoryArray.includes('Erkek') && subcategoryArray.includes('Karma'))) {
                        subcategoryArray = [
                            ...subcategoryArray,
                            'Kız',
                            'Erkek',
                            'Karma'
                        ]
                    }
                    if (typeArray[i] === 'Okul'
                        && !(subcategoryArray.includes('Anaokulu')
                            && subcategoryArray.includes('İlkokul')
                            && subcategoryArray.includes('Ortaokul')
                            && subcategoryArray.includes('Lise')
                            && subcategoryArray.includes('Kolej'))) {
                        subcategoryArray = [
                            ...subcategoryArray,
                            'Anaokulu',
                            'İlkokul',
                            'Ortaokul',
                            'Lise',
                            'Kolej'
                        ]

                    }
                }
            }
            dispatch(setType(typeArray))
            dispatch(setCategory(categoryArray))
            dispatch(setSubcategory(subcategoryArray))
        }

    }, [allFilters, tagname, typeArray.length, categoryArray.length, subcategoryArray.length])

    useEffect(() => {
        allFilters.find((filter: any, index: any) => {
            if (filter.selected && filter.tagName === 'Üniversite') {
                allFilters.find((filter: any, index: any) => {
                    if (filter.type === 'group2' && categoryArray.length > 0) {
                        dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                    }
                    if (filter.type === 'group3' && typeArray.length > 0) {
                        dispatch(setFilterDisabledStatus({ disabled: false, index: index }))
                    }
                    if (filter.type === 'group4') {
                        dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                    }
                })
            }

            if (filter.selected && filter.tagName === 'Okul') {
                allFilters.find((filter: any, index: any) => {
                    if (filter.type === 'group2' && categoryArray.length > 0 && typeArray.length > 0) {
                        dispatch(setFilterDisabledStatus({ disabled: false, index: index }))
                    }
                    if (filter.type === 'group3' && typeArray.length > 0) {
                        dispatch(setFilterDisabledStatus({ disabled: false, index: index }))
                    }
                    if (filter.type === 'group4' && typeArray.length > 0) {
                        dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                    }
                })
            }
            if (filter.selected && filter.tagName === 'Yurt') {
                allFilters.find((filter: any, index: any) => {
                    if (filter.type === 'group2' && typeArray.length > 0) {
                        dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                    }
                    if (filter.type === 'group3' && typeArray.length > 0) {
                        dispatch(setFilterDisabledStatus({ disabled: false, index: index }))
                    }
                    if (filter.type === 'group4' && categoryArray.length > 0 && typeArray.length > 0) {
                        dispatch(setFilterDisabledStatus({ disabled: false, index: index }))
                    }
                })
            }
            //başta categori ve subları gizle
            if (selectedArray.length == 0 && categoryArray.length === 0) {
                if (filter.type === 'group2') {
                    dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                }
                if (filter.type === 'group3') {
                    dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                }
                if (filter.type === 'group4') {
                    dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                }
            }
            //categori yoksa sublari gizle
            if (categoryArray.length === 0) {
                if (filter.type === 'group2') {
                    dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                }
                if (filter.type === 'group4') {
                    dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                }
            }

            //type yoksa sub ve categorileri gizle
            if (typeArray.length === 0) {
                if (filter.type === 'group2') {
                    dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                }
                if (filter.type === 'group3') {
                    dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                }
                if (filter.type === 'group4') {
                    dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                }
            }


            //iki tane seçiliylse belirli subları göster
            if (typeArray.length > 1 && categoryArray.length > 0) {
                if (typeArray.includes('Üniversite') && typeArray.includes('Yurt')) {
                    if (filter.type === 'group2') {
                        dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                    }
                    if (filter.type === 'group3') {
                        dispatch(setFilterDisabledStatus({ disabled: false, index: index }))
                    }
                    if (filter.type === 'group4') {
                        dispatch(setFilterDisabledStatus({ disabled: false, index: index }))
                    }
                }
                if (typeArray.includes('Üniversite') && typeArray.includes('Okul')) {
                    if (filter.type === 'group2') {
                        dispatch(setFilterDisabledStatus({ disabled: false, index: index }))
                    }
                    if (filter.type === 'group3') {
                        dispatch(setFilterDisabledStatus({ disabled: false, index: index }))
                    }
                    if (filter.type === 'group4') {
                        dispatch(setFilterDisabledStatus({ disabled: true, index: index }))
                    }
                }
                if (typeArray.includes('Yurt') && typeArray.includes('Okul') ||
                    typeArray.includes('Yurt') && typeArray.includes('Okul') && typeArray.includes('Üniversite')) {
                    if (filter.type === 'group2') {
                        dispatch(setFilterDisabledStatus({ disabled: false, index: index }))
                    }
                    if (filter.type === 'group3') {
                        dispatch(setFilterDisabledStatus({ disabled: false, index: index }))
                    }
                    if (filter.type === 'group4') {
                        dispatch(setFilterDisabledStatus({ disabled: false, index: index }))
                    }
                }

            }

            //yurt secili degilse onun seçili sublarını kaldır
            if (!(typeArray.includes('Yurt'))) {
                if (filter.type === 'group4') {
                    dispatch(setFilterSelectedStatus({ index: index, selected: false }));
                }
            }
            if (!(typeArray.includes('Okul'))) {
                if (filter.type === 'group2') {
                    dispatch(setFilterSelectedStatus({ index: index, selected: false }));
                }
            }

            //kategori yoksa subkategorileri temizle
            if (categoryArray.length === 0 && subcategoryArray.length > 0) {
                dispatch(setSubcategory([]))
                if (filter.type === 'group2') {
                    dispatch(setFilterSelectedStatus({ index: index, selected: false }));
                }
                if (filter.type === 'group4') {
                    dispatch(setFilterSelectedStatus({ index: index, selected: false }));
                }
            }
            if (typeArray.length === 0) {
                dispatch(setCategory([]))
                dispatch(setSubcategory([]))
                categoryArray = []
                subcategoryArray = []
                if (filter.type === 'group2') {
                    dispatch(setFilterSelectedStatus({ index: index, selected: false }));
                }
                if (filter.type === 'group3') {
                    dispatch(setFilterSelectedStatus({ index: index, selected: false }));
                }
                if (filter.type === 'group4') {
                    dispatch(setFilterSelectedStatus({ index: index, selected: false }));
                }
            }
        })

        dispatch(setType(typeArray))
        dispatch(setCategory(categoryArray))
        dispatch(setSubcategory(subcategoryArray))

    }, [action, typeArray.length, categoryArray.length, subcategoryArray.length])

    useEffect(() => {
        setTime(resultTime)
        setScore(resultScore)
    }, [resultTime, resultScore])

    const selectedTag = (selectedTagIndex: number, filterSelected: boolean, filterDisabled: boolean, selectedTagName: string) => {
        setAction(!action);
        if (filterSelected) {
            setTagname(selectedTagName);
        }
        if (filterDisabled) {
            return;
        }
        dispatch(setFilterSelectedStatus({ index: selectedTagIndex, selected: filterSelected }));
    }

    const clearAll = () => {
        dispatch(setAllFilters(filters))
        dispatch(setType(''))
        dispatch(setCategory(''))
        dispatch(setSubcategory(''))
        dispatch(setResultTime(0))
        dispatch(setResultScore(0))
    }

    return (
        <div className='filter-data'>
            <div className="container">
                <div className="main-content">
                    {allFilters && allFilters.map(((filter: any, index: number) => <div key={index} className={`${filter.selected ? 'tag-selected' : 'tag'} ${filter.disabled ? 'disabled' : ''}`} onClick={() => selectedTag(index, !filter.selected, filter.disabled, filter.tagName)}>
                        {filter.icon && <img src={filter.icon} alt={filter.icon} />}
                        <div className="text">{filter.tagName}</div>
                    </div>))}
                </div>
                <div className="bottom-content">
                    <div className='group'>
                        <img src="icons/info.svg" alt="info" />
                        <div className='text'>{score} sonuç {time} saniyede <br />listelendi</div>
                    </div>
                    <Button label="Temizle" onClick={clearAll} />
                </div>
            </div>
        </div >

    )
}

import { configureStore } from '@reduxjs/toolkit';
import { basemapSlice } from './features/basemapSlice';
import { dropdownStateSlice } from './features/dropdownButtonState';
import { dropdownResultsSlice } from './features/dropdownResults';
import { filterSlice} from './features/filterSlice';
import { searchResultSlice } from './features/searchResult';

export const store = configureStore({
    reducer: {
        basemaps: basemapSlice.reducer,
        searchResults: searchResultSlice.reducer,
        dropdownResults: dropdownResultsSlice.reducer,
        dropdownState: dropdownStateSlice.reducer,
        filters: filterSlice.reducer
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

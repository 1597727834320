import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    city: '',
    town: '',
    neighborhood: '',
}

export const dropdownResultsSlice = createSlice({
    name: 'dropdownResults',
    initialState,
    reducers: {
        cityResult: (state: any, action) => {
            state.city = action.payload;
        },
        townResult: (state: any, action) => {
            state.town = action.payload;
        },
        neighborhoodResult: (state: any, action) => {
            state.neighborhood = action.payload;
        }
    },
})

export const { cityResult, townResult, neighborhoodResult } = dropdownResultsSlice.actions


export default dropdownResultsSlice.reducer
import { createSlice } from '@reduxjs/toolkit';

const initialState: any = {
    value: [],
    extentedValue: [],
    hoveredResult: {},
    selectedResult: {},
    clickedFeatureId: null,
    resultTime: 0,
    resultScore: 0,
    searchListVisibilty: false,
    selectedListVisibilty: false,
    extent: [3048920.18421693, 4159111.9500435647, 4774562.534783069, 5334407.696956435]
}

export const searchResultSlice = createSlice({
    name: 'searchResult',
    initialState,
    reducers: {
        setResults: (state: any, action) => {
            state.value = action.payload;
        },
        setExtentedResults: (state: any, action) => {
            state.extentedValue = action.payload;
        },
        setHoveredResult: (state: any, action) => {
            state.hoveredResult = action.payload;
        },
        setSelectedResult: (state: any, action) => {
            state.selectedResult = action.payload;
        },
        setClickedFeatureId: (state: any, action) => {
            state.clickedFeatureId = action.payload;
        },
        setResultTime: (state: any, action) => {
            state.resultTime = action.payload;
        },
        setResultScore: (state: any, action) => {
            state.resultScore = action.payload;
        },
        searchListVisibilty: (state: any, action) => {
            state.searchListVisibilty = action.payload;
        },
        selectedListVisibilty: (state: any, action) => {
            state.selectedListVisibilty = action.payload;
        },
        setExtent: (state: any, action) => {
            state.extent = action.payload;
        }
    },
})

export const { setResults,setExtentedResults, setHoveredResult, setClickedFeatureId, setSelectedResult, setResultTime, setResultScore, searchListVisibilty, selectedListVisibilty } = searchResultSlice.actions

export default searchResultSlice.reducer
import './Distance.scss';
import { useState } from 'react';

export const Distance = () => {
  const [slider, setSlider] = useState(0)
  const sliderValueChanged = (value: any) => {
    setSlider(value);
  }

  return (
    <div className='Distance'>
      <div className='select-point'>
        <div className='text'>Haritadan bir merkez noktası seçin</div>
        <img src="icons/close.svg" alt="close" />
      </div>
      <div className='slider-group'>
        <div className='text'>0 m</div>
        <input type='range' className='slider' value={slider} onChange={(e:any) => sliderValueChanged(e.target.value)} />
        <div className='text' style={{ paddingLeft: '20px' }}>600 m</div>
      </div>

    </div>
  )
}
